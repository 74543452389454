<template>
  <div>
    <bread-crumbs />
    <TripAiBot :options="options" />
    <customer-details-info @redirect="redirectBack" />

    <!-- Listing -->
    <ListingLayout
      :sub-screen="1"
      :is-loading="isLoading"
      :meta="meta"
      :headers="_headers"
      :items="list"
      :bread-crumbs="false"
      no-filters
      show-date
      :date-validation="false"
      @filterOption="onFiltersChange"
    >
      <template v-slot:interval_id="{ item }">
        <div
          v-if="$admin.hasAccessTo('vehicle-trip-details.list')"
          class="open-link"
        >
          <!-- @click="goToDetailPage(item.interval_id)" -->
          <router-link :to="goToDetailPage(item)">
            {{ item.interval_id }}
          </router-link>
          <!-- <router-link
            :to="{
              name: 'trip-detail',
              params: { id: item.interval_id },
              query: {
                imei: item.imei,
                vehicle_id: item.vehicle_id,
                vehicle_tech: customerInfo.vehicle_tech,
              },
            }"
          >
            {{ item.interval_id }}
          </router-link> -->
        </div>
        <div v-else>
          {{ item.interval_id }}
        </div>
      </template>

      <template v-slot:start_datetime="{ item }">
        <!-- <div>{{ item.start_datetime | formatDate }}</div> -->
        <div>{{ item.start_datetime }}</div>
      </template>
      <template v-slot:end_datetime="{ item }">
        <!-- <div>{{ item.end_datetime | formatDate }}</div> -->
        <div>{{ item.end_datetime }}</div>
      </template>

      <template v-slot:distance="{ item }">
        <div>
          {{ item.distance ? parseFloat(item.distance).toFixed(2) : "" }}
        </div>
      </template>

      <!-- add Filters -->
      <template #addFilters>
        <v-menu
          v-model="filterMenu"
          :close-on-content-click="false"
          :nudge-width="100"
          content-class="filterMenDropDown"
          offset-x
        >
          <template v-slot:activator="{ on, attrs }">
            <v-card>
              <v-text-field
                class="filterDropDown"
                v-bind="attrs"
                label="Filters"
                :value="showSelectedFilters"
                readonly
                v-on="on"
              />
            </v-card>
          </template>
          <v-card class="my-0 py-0">
            <v-list class="my-0">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <h4>Filters</h4>
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn
                    color="primary"
                    text
                    small
                    @click="clearFilters"
                  >
                    Clear
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
            <v-divider />
            <v-list>
              <v-list-item
                v-for="(item, i) in filterOptions"
                :key="i"
              >
                <div>
                  <v-list-item-action class="py-0 my-0">
                    <v-checkbox
                      ref="myFilterCheck"
                      v-model="selectedFilter"
                      :label="item.name"
                      :value="item.id"
                      hide-details
                      :ripple="false"
                      @change="
                        onFilterChange(
                          item.id,
                          selectedFilter.includes(item.id)
                        )
                      "
                    />
                  </v-list-item-action>
                  <v-list-item-content
                    v-if="
                      item.id == 'distance' && selectedFilter.includes(item.id)
                    "
                    class="py-0 my-0"
                  >
                    <div class="distance-Options">
                      <div>
                        <v-select
                          v-model="isDistance.operator"
                          :items="[
                            // { value: '=', title: '=' },
                            { value: '>', title: '>' },
                            { value: '<', title: '<' },
                          ]"
                          item-text="title"
                          item-value="value"
                          class="py-0 pt-2"
                          @change="onDistanceChange"
                        />
                      </div>
                      <div>
                        <v-text-field
                          v-model="isDistance.value"
                          :label="$t('distance')"
                          type="number"
                          min="0"
                          max="100"
                          class="py-0 pt-2"
                          @change="onDistanceChange"
                        />
                      </div>
                    </div>
                  </v-list-item-content>
                  <v-list-item-content
                    v-if="
                      item.id == 'duration' && selectedFilter.includes(item.id)
                    "
                    class="py-0 my-0"
                  >
                    <div class="duration-Options">
                      <div>
                        <v-select
                          v-model="isDuration.operator"
                          :items="[
                            { value: '=', title: '=' },
                            { value: '>', title: '>' },
                            { value: '<', title: '<' },
                          ]"
                          item-text="title"
                          item-value="value"
                          class="py-0 pt-2"
                          @change="onDurationChange"
                        />
                      </div>
                      <div>
                        <v-text-field
                          v-model="isDuration.value"
                          :label="$t('durationMin')"
                          type="number"
                          min="0"
                          max="100"
                          class="py-0 pt-2"
                          @change="onDurationChange"
                        />
                      </div>
                    </div>
                  </v-list-item-content>
                  <v-list-item-content
                    v-if="
                      item.id == 'mileage' && selectedFilter.includes(item.id)
                    "
                    class="py-0 my-0"
                  >
                    <div class="duration-Options">
                      <div>
                        <v-select
                          v-model="isMileage.operator"
                          :items="[
                            { value: '=', title: '=' },
                            { value: '>', title: '>' },
                            { value: '<', title: '<' },
                          ]"
                          item-text="title"
                          item-value="value"
                          class="py-0 pt-2"
                          @change="onMileageChange"
                        />
                      </div>
                      <div>
                        <v-text-field
                          v-model="isMileage.value"
                          :label="
                            vTech == 'ev' ? $t('ev_mileage') : $t('ice_mileage')
                          "
                          type="number"
                          min="0"
                          max="100"
                          class="py-0 pt-2"
                          @change="onMileageChange"
                        />
                      </div>
                    </div>
                  </v-list-item-content>
                </div>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </template>
      <!-- add Filters -->

      <!-- Dashboard Tags -->
      <template #description>
        <!-- :items="tripDashboard" -->
        <dashboard-slider-cards
          :is-loading="isLoading"
          :items="dashboardTags"
        />
      </template>
      <!-- Dashboard Tags -->

      <!-- Action Modals -->
      <template #actionModals>
        <!-- <v-toolbar
          flat
          class="action-toolbar"
        > -->
        <refresh-button
          v-if="list.length > 0"
          :is-loading="isLoading"
          @reload="fetchData"
        />

        <!-- </v-toolbar> -->
      </template>
      <!-- Action Modals -->
    </ListingLayout>
  </div>
</template>

<script>
import { ListingLayoutMixins } from "@/mixins/ListingLayoutMixins";
import { mapGetters } from "vuex";
// import { debounce } from "lodash";
export default {
  components: {
    BreadCrumbs: () => import("@/layouts/core/BreadCrumbs.vue"),
    TripAiBot: () => import("../utils/TripAiBot.vue"),
    CustomerDetailsInfo: () =>
      import("@/components/sections/CustomerDetailsInfo.vue"),
    DashboardSliderCards: () =>
      import("../../../../components/sections/DashboardSliderCards.vue"),
    ListingLayout: () => import("@/layouts/section/ListingLayout.vue"),
    refreshButton: () => import("@/components/base/RefreshButton.vue"),
  },
  mixins: [ListingLayoutMixins],

  data() {
    return {
      filters: {},
      subFilters: {},
      customFilter: {},

      filterMenu: false,
      selectedFilter: [],

      isDistance: {},
      isDuration: {},
      isMileage: {},
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
      isLoading: "vehicleTrips/getIsLoading",
      meta: "vehicleTrips/getMeta",
      list: "vehicleTrips/getVehicleTripList",
      //
      tripDashboard: "vehicleTrips/getTripDashboard",
      //
      // When Query Param don't have Tenant id
      customerInfo: "vehicles/getVehicle",
    }),
    _headers() {
      const vTech = this.tripDashboard?.vehicle_tech;
      const head = [
        {
          text: this.$t("trip_id"),
          align: "center",
          sortable: true,
          value: "interval_id",
        },
        {
          text: this.$t("start_datetime"),
          align: "center",
          sortable: false,
          value: "start_datetime",
        },
        {
          text: this.$t("end_datetime"),
          align: "center",
          sortable: false,
          value: "end_datetime",
        },
        // {
        //   text: this.$t("start_soc"),
        //   align: "center",
        //   sortable: false,
        //   value: "start_soc",
        // },
        // {
        //   text: this.$t("end_soc"),
        //   align: "center",
        //   sortable: false,
        //   value: "end_soc",
        // },
        {
          text: this.$t("distance"),
          align: "center",
          sortable: false,
          value: "distance",
        },
        {
          text:
            vTech == "ice"
              ? this.$t("ice_avg_mileage")
              : this.$t("avg_mileage"),
          align: "center",
          sortable: false,
          value: "avg_mileage",
        },
        {
          text: this.$t("avg_speed"),
          align: "center",
          sortable: false,
          value: "avg_speed",
        },

        {
          text: this.$t("duration"),
          align: "center",
          sortable: false,
          value: "duration",
        },
        // {
        //   text: this.$t("battery_consumed"),
        //   align: "center",
        //   sortable: false,
        //   value: "battery_consumed",
        // },

        // {
        //   text: this.$t("max_speed"),
        //   align: "center",
        //   sortable: false,
        //   value: "max_speed",
        // },

        // {
        //   text: this.$t("monetary_cost"),
        //   align: "center",
        //   sortable: false,
        //   value: "monetary_cost",
        // },
        // {
        //   text: this.$t("electricity_per_unit_price"),
        //   align: "center",
        //   sortable: false,
        //   value: "electricity_per_unit_price",
        // },
      ];

      return head;
    },
    filterOptions() {
      const arr = [
        {
          id: "distance",
          name: "Distance",
        },
        {
          id: "duration",
          name: "Duration",
        },
        {
          id: "mileage",
          name: "Mileage",
        },
      ];
      if (this.authUser.isAdmin) {
        arr.push({
          id: "review_required",
          name: "Review Required",
        });
      }

      return arr;
    },
    showSelectedFilters() {
      const arr = [];
      this.selectedFilter.forEach((el) => {
        arr.push(el);
      });
      return arr.length > 0
        ? arr.map((r) => r.replace("_", " ")).join(", ")
        : "";
    },
    dashboardTags() {
      const obj = {
        all_trips: this.tripDashboard.all_trips,
        total_distance_travelled: this.tripDashboard.total_distance_travelled,
        total_average_mileage: this.tripDashboard.avg_mileage,
        total_average_speed: this.tripDashboard.total_avg_speed,
        total_average_duration: this.tripDashboard.total_avg_duration,
        total_duration: this.tripDashboard.total_duration,
      };
      return obj;
    },
    vTech() {
      return this.customerInfo?.vehicle_tech || "ev";
    },
  },
  watch: {
    options() {
      this.fetchData();
    },
  },
  beforeMount() {
    this.selectedFilter = this.$route?.query.filters
      ? this.$route?.query.filters
      : [];
    if (this.selectedFilter.includes("distance")) {
      this.isDistance.operator = this.$route.query.distance_operator;
      this.isDistance.value = this.$route.query.distance_value;

      this.onDistanceChange(false);
    }
    if (this.selectedFilter.includes("duration")) {
      this.isDuration.operator = this.$route.query.duration_operator;
      this.isDuration.value = this.$route.query.duration_value;

      this.onDurationChange(false);
    }
    if (this.selectedFilter.includes("mileage")) {
      this.isMileage.operator = this.$route.query.mileage_operator;
      this.isMileage.value = this.$route.query.mileage_value;

      this.onMileageChange(false);
    }
    if (this.selectedFilter.includes("review_required")) {
      this.customFilter.review_required = 1;
    }
  },
  beforeDestroy() {
    this.$store.commit("vehicleTrips/SET_VEHICEL_TRIPS", []);
    this.$store.commit("vehicleTrips/SET_META", {
      meta: { page: null },
    });
    // state.meta.page = data.meta.current_page;
    // state.meta.itemsPerPage = data.meta.per_page;
    // state.meta.lastPage = data.meta.last_page;
    // state.meta.totalItems = data.meta.total;
  },
  methods: {
    async fetchData() {
      const imei = this.$route?.params?.imei;
      this.options.vehicle_id = this.$route.query.vehicle_id;
      await this.$store.dispatch("vehicleTrips/tripsDashboard", {
        ...this.options,
        ...this.customFilter,
        imei,
      });
      await this.$store
        .dispatch("vehicleTrips/list", {
          ...this.options,
          ...this.customFilter,
          imei,
        })
        .then(() => {});
    },
    // goToListingPage(row) {
    //   const params = {
    //     product: this.options.product,
    //     fleetId: this.options.fleetId ?? undefined,
    //     oem_b2b_ids: this.options.oem_b2b_ids ?? undefined,
    //     tenant_id: this.options.tenant_id,
    //     search: this.options.search ?? undefined,
    //     vehicle_id: row.vehicle_id,
    //     page: this.options.page,
    //     perPage: this.options.itemsPerPage,
    //   };
    //   return this.$router.push({
    //     name: "trip-listing",
    //     params: { imei: row.iot_imei },
    //     query: params,
    //   });
    // },
    redirectBack() {
      this.$store.commit("vehicleTrips/SET_VEHICEL_TRIPS", []);
      const params = {
        product: this.$route.query.product ?? undefined,
        fleetId: this.$route.query.fleetId ?? undefined,
        oem_b2b_ids: this.$route.query.oem_b2b_ids ?? undefined,
        tenant_id: this.$route.query.tenant_id ?? undefined,
        search: this.$route.query.search ?? undefined,
        page: this.$route.query.page ?? undefined,
        perPage: this.$route.query.perPage ?? undefined,
      };
      return this.$router.push({
        name: "trips",
        query: params,
      });
    },
    goToDetailPage(item) {
      const imei = this.$route.params.imei;
      const dateOpt = {
        dateType: this.options.dateType,
        // dateRange: this.options.date ?? undefined,
      };
      if (dateOpt.dateType == "custom") {
        dateOpt.dateRange = this.options.date;
      }
      dateOpt.dateRange;
      const filters = {
        filters: this.selectedFilter,
      };
      if (this.selectedFilter.includes("distance")) {
        filters.distance_operator = this.customFilter.distance_operator;
        filters.distance_value = this.customFilter.distance_value;
      }
      if (this.selectedFilter.includes("duration")) {
        filters.duration_operator = this.customFilter.duration_operator;
        filters.duration_value = this.customFilter.duration_value;
      }
      if (this.selectedFilter.includes("mileage")) {
        filters.mileage_operator = this.customFilter.mileage_operator;
        filters.mileage_value = this.customFilter.mileage_value;
      }
      const params = {
        ...this.$route?.query,
        imei: imei,
        trip_id: item.id,
        tID: this.customerInfo.tenant_id,
        vehicle_tech: this.customerInfo.vehicle_tech,
        p1: this.options.page,
        pp1: this.options.itemsPerPage,
        ...dateOpt,
        ...filters,
      };
      return {
        name: "trip-detail",
        params: { id: item.interval_id },
        query: params,
      };
      // query: {
      //   product: this.$route.query.product,
      //   fleetId: this.$route.query.fleetId ?? undefined,
      //   oem_b2b_ids: this.$route.query.oem_b2b_ids ?? undefined,
      //   tenant_id: this.$route.query.tenant_id,
      //   search: this.$route.query.search ?? undefined,
      //   vehicle_id: this.$route.query.vehicle_id,
      //   imei: imei,
      //   ...dateOpt,
      //   ...filters,
      // },
      // query: params,
    },
    onDistanceChange(fetch = true) {
      if (this.isDistance.operator && this.isDistance.value) {
        this.customFilter.distance_operator = this.isDistance.operator;
        this.customFilter.distance_value = this.isDistance.value;
        if (fetch) {
          this.options.page = 1;
          this.fetchData();
        }
      }
    },
    onDurationChange(fetch = true) {
      if (this.isDuration.operator && this.isDuration.value) {
        this.customFilter.duration_operator = this.isDuration.operator;
        this.customFilter.duration_value = this.isDuration.value;
        if (fetch) {
          this.options.page = 1;
          this.fetchData();
        }
      }
    },
    onMileageChange(fetch = true) {
      if (this.isMileage.operator && this.isMileage.value) {
        this.customFilter.mileage_operator = this.isMileage.operator;
        this.customFilter.mileage_value = this.isMileage.value;
        if (fetch) {
          this.options.page = 1;
          this.fetchData();
        }
      }
    },
    onFilterChange(i, snd) {
      let v = this.selectedFilter;

      // if (!v.includes("interval")) this.options.interval = null;
      // this.options.mobile_ack = v.includes("mob_ack") ? 1 : 0;
      // this.options.review_required = v.includes("review_required") ? 1 : 0;
      if (v.includes("review_required")) {
        this.customFilter.review_required = 1;
      } else {
        delete this.customFilter.review_required;
      }

      if (!v.includes("distance")) {
        this.isDistance = {};
        this.customFilter.distance_operator = null;
        this.customFilter.distance_value = null;
      }
      if (!v.includes("duration")) {
        this.isDuration = {};
        this.customFilter.duration_operator = null;
        this.customFilter.duration_value = null;
      }
      if (!v.includes("mileage")) {
        this.isMileage = {};
        this.customFilter.mileage_operator = null;
        this.customFilter.mileage_value = null;
      }
      // if (!v.includes("ignition ")) this.options.ingnition = null;
      // if (!v.includes("latency")) {
      //   this.isLatency = {};
      //   this.options.latency_operator = null;
      //   this.options.latency_value = null;
      // }
      if (i == "review_required" || snd == false) {
        this.options.page = 1;
        this.fetchData();
      }
    },

    clearFilters() {
      this.selectedFilter = [];
      this.isDistance = {};
      this.customFilter.distance_operator = null;
      this.customFilter.distance_value = null;
      this.isDuration = {};
      this.customFilter.duration_operator = null;
      this.customFilter.duration_value = null;
      this.isMileage = {};
      this.customFilter.mileage_operator = null;
      this.customFilter.mileage_value = null;
      this.customFilter.review_required = null;
      // this.options.review_required;
      // this.options.ingnition = null;
      this.filterMenu = false;
      // this.onTimeIntervalChange();
      this.fetchData();
    },
  },
};
</script>
<style lang="sass" scoped>
.filterDropDown
  padding: 0 !important
  margin: 0 !important

.filterDropDown
  :deep input
    text-transform:  capitalize !important

.distance-Options , .duration-Options
  display: flex
  gap: 1rem
  div:first-child
    flex-basis: 25%
  div:last-child
    flex-basis: 75%
</style>
